const growerVarieties = {
	"chi-1": [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 80 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 50 },
		{ perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 50 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 50 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 50 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 50 },
		{ perfName: "Rom6c", growerName: "Rom6c", varietyCode: "LSG3-0006c", weightRows: 50 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 50 },
		{ perfName: "Butter1b10", growerName: "Butter1b10", varietyCode: "LSG6-0001b10", weightRows: 50 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 50 },
		// { perfName: "Nutrigreens", growerName: "Nutrigreens Mix", varietyCode: "LSX9-0001-LSX9-0002", weightRows: 50 },
		// { perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002", weightRows: 20 },
		{ perfName: "Basil1", growerName: "Basil1", varietyCode: "OBX1-0001", weightRows: 30 },
		{ perfName: "Basil2", growerName: "Basil2", varietyCode: "OBX1-0002", weightRows: 30 },
	],
	pen: [
		// { perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 40 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 40 },
		{ perfName: "Sunny3", growerName: "Sunny3", varietyCode: "LSG1-0003", weightRows: 40 },
		{ perfName: "Sunny7", growerName: "Sunny7", varietyCode: "LSG1-0007", weightRows: 40 },
		{ perfName: "Sunny10", growerName: "Sunny10", varietyCode: "LSG1-0010", weightRows: 40 },
		{ perfName: "Sunny44b9c", growerName: "Sunny44b9c", varietyCode: "LSG1-0044b9c", weightRows: 40 },
		{ perfName: "Sunny11b9c", growerName: "Sunny11b9c", varietyCode: "LSG1-0011b9c", weightRows: 20 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 40 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 20 },
		{ perfName: "Rom6c", growerName: "Rom6c", varietyCode: "LSG3-0006c", weightRows: 20 },
		// { perfName: "Red2b10", growerName: "Red2b10", varietyCode: "LSR4-0002b10", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		// { perfName: "Red4", growerName: "Red4", varietyCode: "LSR4-0004", weightRows: 20 },
		{ perfName: "Butter1b10", growerName: "Butter1b10", varietyCode: "LSG6-0001b10", weightRows: 20 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 20 },
		// { perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002", weightRows: 20 },
		{ perfName: "Basil1", growerName: "Basil1", varietyCode: "OBX1-0001", weightRows: 20 },
		{ perfName: "Red4b10", growerName: "Red4b10", varietyCode: "LSR4-0004b10", weightRows: 20 },
	],
	car: [
		// { perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 20 },
		{ perfName: "Sunny24", growerName: "Sunny24", varietyCode: "LSG1-0024", weightRows: 20 },
		// { perfName: "Sunny7", growerName: "Sunny7", varietyCode: "LSG1-0007", weightRows: 20 },
		// { perfName: "Sunny3", growerName: "Sunny3", varietyCode: "LSG1-0003", weightRows: 20 },
		{ perfName: "Sunny10", growerName: "Sunny10", varietyCode: "LSG1-0010", weightRows: 20 },
		{ perfName: "Sunny11b9c", growerName: "Sunny11b9c", varietyCode: "LSG1-0011b9c", weightRows: 20 },
		{ perfName: "Sunny44b9c", growerName: "Sunny44b9c", varietyCode: "LSG1-0044b9c", weightRows: 20 },
		{ perfName: "Sunny3", growerName: "Sunny3", varietyCode: "LSG1-0003", weightRows: 20 },
		{ perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
		// { perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 20 },
		{ perfName: "Rom6c", growerName: "Rom6c", varietyCode: "LSG3-0006c", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Ser14", growerName: "Ser14", varietyCode: "LSG2-0014", weightRows: 20 },
		// { perfName: "Butter1", growerName: "Butter1", varietyCode: "LSG6-0001", weightRows: 20 },
		// { perfName: "Butter", growerName: "Ezmari", varietyCode: "LSG6-0002", weightRows: 20 },
		// { perfName: "Beet", growerName: "Vulture", varietyCode: "BVG1-0002", weightRows: 20 },
		// { perfName: "Beet", growerName: "Fresh Pak", varietyCode: "BVG1-0005", weightRows: 20 },
	],
	ohi: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
		// { perfName: "Ser1", growerName: "Ser1", varietyCode: "LSG2-0001", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Red2b10", growerName: "Red2b10", varietyCode: "LSR4-0002b10", weightRows: 20 },
		// { perfName: "Spinach", growerName: "Sunangel", varietyCode: "SOX1-0007", weightRows: 20 },
		// { perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		// { perfName: "Beet", growerName: "Fresh Start", varietyCode: "BVG1-0001", weightRows: 20 },
	],
	wdc: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 30 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 30 },
		{ perfName: "Sunny11b9c", growerName: "Sunny11b9c", varietyCode: "LSG1-0011b9c", weightRows: 30 },
		{ perfName: "Sunny24", growerName: "Sunny24", varietyCode: "LSG1-0024", weightRows: 30 },
		{ perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 20 },
		{ perfName: "Rom6c", growerName: "Rom6c", varietyCode: "LSG3-0006c", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Red2b10", growerName: "Red2b10", varietyCode: "LSR4-0002b10", weightRows: 20 },
		// { perfName: "Spinach", growerName: "Sunangel", varietyCode: "SOX1-0007", weightRows: 20 },
	],
	ele: [],
	ilg: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 30 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Bat10b12c", growerName: "Bat10b12c", varietyCode: "LSG5-0010b12c", weightRows: 20 },
		{ perfName: "Bat10", growerName: "Bat10", varietyCode: "LSG5-0010", weightRows: 20 },
		{ perfName: "Bat10b12", growerName: "Bat10b12", varietyCode: "LGG5-0010b12", weightRows: 20 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 20 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 20 },
		// { perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
	],
	tex: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 20 },
		// { perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
		{ perfName: "Sunny2b12c", growerName: "Sunny2b12c", varietyCode: "LSG1-0002b12c", weightRows: 20 },
		{ perfName: "Sunny11b9c", growerName: "Sunny11b9c", varietyCode: "LSG1-0011b9c", weightRows: 20 },
		{ perfName: "Sunny24", growerName: "Sunny24", varietyCode: "LSG1-0024", weightRows: 20 },
		{ perfName: "Sunny44b9c", growerName: "Sunny44b9c", varietyCode: "LSG1-0044b9c", weightRows: 20 },
	],
	gag: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 20 },
		{ perfName: "Red1b10", growerName: "Red1b10", varietyCode: "LSR4-0001b10", weightRows: 20 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 20 },
		{ perfName: "Bat10", growerName: "Bat10", varietyCode: "LSG5-0010", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		{ perfName: "Rom5", growerName: "Rom5", varietyCode: "LSG3-0005", weightRows: 20 },
		{ perfName: "Rom6", growerName: "Rom6", varietyCode: "LSG3-0006", weightRows: 20 },
		{ perfName: "Ser1b10", growerName: "Ser1b10", varietyCode: "LSG2-0001b10", weightRows: 20 },
		// { perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
	],
	neh: [
		{ perfName: "Sunny1", growerName: "Sunny1", varietyCode: "LSG1-0001", weightRows: 20 },
		{ perfName: "Sunny1b10", growerName: "Sunny1b10", varietyCode: "LSG1-0001b10", weightRows: 20 },
		{ perfName: "Ser2", growerName: "Ser2", varietyCode: "LSG2-0002", weightRows: 20 },
		{ perfName: "Red8", growerName: "Red8", varietyCode: "LSR4-0008", weightRows: 20 },
		{ perfName: "Spice", growerName: "Spice Mix", varietyCode: "LSR4-0033-EVX1-0001-BJG1-0001-MGG1-0001", weightRows: 20 },
		{ perfName: "Butter2b10", growerName: "Butter2b10", varietyCode: "LSG6-0002b10", weightRows: 20 },
		{ perfName: "Bat9", growerName: "Bat9", varietyCode: "LSG5-0009", weightRows: 20 },
		{ perfName: "Bat9b20", growerName: "Bat9b20", varietyCode: "LSG5-0009b20", weightRows: 20 },
		{ perfName: "Rom2", growerName: "Rom2", varietyCode: "LSG3-0002", weightRows: 20 },
		// { perfName: "Oak Leaf", growerName: "Oak Mix", varietyCode: "LSG7-0006-LSG7-0007-LSR7-0008", weightRows: 20 },
	],
};
export default growerVarieties;
